<template>
    <div class="login">
        <div class="download-bg">
            <div class="download-content">
                <img src="../../assets/download/logo.png" class="logo" alt="">
<!--                <div class="center-text">-->
<!--                    <p class="over-line-1">{{$t('language.downloadMsg1')}}</p>-->
<!--                    <p class="over-line-1">{{$t('language.downloadMsg2')}}</p>-->
<!--                </div>-->
                <img src="../../assets/download/phone.png" class="content-img" alt="">
            </div>
            <div class="bottom-button-group">
                <div class="one" @click="iosJump">
                    <img src="../../assets/download/ios.png" alt="">
                    <span class="over-line-1">{{$t('language.downloadMsg3')}}</span>
                </div>
                <div class="one" @click="androidJump">
                    <img src="../../assets/download/android.png" alt="">
                    <span class="over-line-1">{{$t('language.downloadMsg4')}}</span>
                </div>
            </div>
<!--            <p class="bottom-text on" >点滴聚合未来</p>-->
<!--            <p class="bottom-text m-b-50">THE FUTURE IS TICKING</p>-->
        </div>
    </div>
</template>

<script>
    import Cms from "../../api/cms";
    export default {
        name: 'Download',

        props: {},
        data() {
            return {
                Android: '',
                IOS: ''
            }
        },
        components: {

        },
        mounted() {
            Cms.version(this, e => {
                this.Android = e.download.android;
                this.IOS = e.download.ios;
            })
        },
        watch: {},
        methods: {
            iosJump(){
                // window.location.href = this.IOS
            },
            androidJump(){
                window.location.href = this.Android
            }
        }
    };
</script>

<style lang="less" scoped>

</style>
